@media print {
  body,
  html,
  #wrapper {
    width: 100%;
  }

  body #__next,
  body #__next > div,
  body #__next > div > div,
  body #__next > div > div > div,
  body #__next > div > div > div > div {
    height: auto;
    overflow: auto;
    padding: 0;
  }

  header {
    display: none !important;
  }

  #helpjuice-widget {
    display: none;
  }
}
