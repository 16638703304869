#helpjuice-widget {
  bottom: 160px !important;
  --right-position: 80px;
}

/* Hide the life preserver SVG path when the widget is open and show our injected close path */
#helpjuice-widget:has(#helpjuice-widget-expanded.hj-shown) svg .st0 {
  display: none;
}

/* Hide our injected close path when the widget is closed */
#helpjuice-widget:has(#helpjuice-widget-expanded:not(.hj-shown)) svg .close {
  display: none;
}

/* Hide the estimated reading time and last updated time in search results */
#helpjuice-widget .article .footer {
  display: none !important;
}

/* Style the header link to still look like a header instead of like a link in the article content */
#helpjuice-widget #article-content-name a {
  font-size: unset;
  text-decoration: none;
}

/*
 * The default height of #helpjuice-widget-expanded is 620px and #helpjuice-widget-content is 500px.
 * It fits well when the screen is 800px. However, on smaller screens, the widget content height
 * should be be 300px less than the screen height. And the widget itself should be 120px taller than
 * the content.
 */
#helpjuice-widget #helpjuice-widget-expanded {
  --content-height: min(100vh - 300px, 500px);
  height: calc(var(--content-height) + 120px) !important;
}

#helpjuice-widget #helpjuice-widget-expanded #helpjuice-widget-content {
  height: var(--content-height) !important;
}

#dismiss-beacon {
  cursor: pointer;
}

#helpjuice-widget.bottomRight {
  right: 10px !important;
  @media not (prefers-reduced-motion) {
    transition: right 0.3s ease;
  }
}

#helpjuice-widget.bottomRight.visible,
#helpjuice-widget.bottomRight:hover {
  right: 80px !important;
}

/*
 * Expand the hover hitbox to extend to the right edge of the screen and be 20px past the left edge
 * of the button.
 */
#helpjuice-widget.bottomRight::before {
  content: '';
  position: absolute;
  height: var(--right-position);
  right: calc(var(--right-position) * -1);
  left: -20px;
  z-index: -1;
}

/* Hide the popup when the beacon is dismissed and not being hovered. */
#helpjuice-widget.bottomRight:not(.visible):not(:hover)
  #helpjuice-widget-expanded.hj-shown {
  display: none !important;
}
